<template>
  <div class="tingli">
    <!-- 听力 -->
    <heads />
    <div class="t-selects">
      <el-select v-model="year" class="select-ver" placeholder="请选择学年" @change="yearChange">
        <el-option v-for="(item, key) in yearList" :key="key" :label="item.name" :value="item.code" />
      </el-select>
      <el-select v-model="version" class="select-grade" placeholder="请选择出版社" @change="versionChange('1')">
        <el-option v-for="(item, key) in versionList" :key="key" :label="item.name" :value="item.name" />
      </el-select>
      <el-select v-model="grade" class="select-grade" placeholder="请选择年级" @change="versionChange">
        <el-option v-for="(item, key) in gradeList" :key="key" :label="item.name" :value="item.code" />
      </el-select>
      <el-select v-model="volume" class="select-grade" placeholder="请选择分册" @change="versionChange">
        <el-option v-for="(item, key) in volumeList" :key="key" :label="item.name" :value="item.code" />
      </el-select>
    </div>
    <div class="t-list">
      <div class="l-title">播放列表</div>
      <!-- 听力列表 -->
      <div class="t-line" />
      <div v-for="(item, key) in listenList" :key="key">
        <div class="t-content">
          <div class="l-name">{{ item.name }}</div>
          <div class="l-size">{{ item.filesize }}</div>
          <div class="l-down" @click="download(item)">下载</div>
          <div class="l-bofang" @click="bofang(item)">播放</div>
        </div>
        <div class="t-line" />
      </div>
      <!-- 底部 -->
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import { get } from 'util/request';
import heads from './common/head.vue';

export default {
  components: {
    heads,
  },
  data() {
    return {
      listenAll: [], // 请求到的所有数据
      listen: [], // 根据学年筛选的数据
      listenList: [], // 最终展示到列表的数据
      year: `${dayjs().isBefore(dayjs(`${dayjs().year()}-08-01`)) ? dayjs().year() - 1 : dayjs().year()}`,
      yearList: [],
      version: '',
      versionList: [],
      grade: '',
      gradeListTemp: [
        { name: '一年级', code: '1' },
        { name: '二年级', code: '2' },
        { name: '三年级', code: '3' },
        { name: '四年级', code: '4' },
        { name: '五年级', code: '5' },
        { name: '六年级', code: '6' },
        { name: '七年级', code: '7' },
        { name: '八年级', code: '8' },
        { name: '九年级', code: '9' },
      ],
      gradeList: [],
      volume: '',
      volumeList: [{ name: '全册', code: '3' }, { name: '上册', code: '1' }, { name: '下册', code: '2' }],
    };
  },
  mounted() {},
  created() {
    // http://192.168.1.74:1000/list.json
    //
    get(`${window.config.listenUrl}?t=${new Date().getTime()}`, {
      noEncrypt: false,
    }).then(result => {
      const { data: { data = [] } = {} } = result || {};
      // 所有数据
      this.listenAll = _.orderBy(data, 'year', 'desc');
      // 筛选的学年数据
      this.year = (_.head(this.listenAll) || {}).year || this.year;
      this.yearList = [
        {
          name: `${this.year}-${_.parseInt(this.year) + 1}学年`,
          code: this.year,
        },
      ];
      // this.yearList = _.map(this.listenAll, v => ({
      //   name: `${v.year}-${_.parseInt(v.year) + 1}学年`,
      //   code: v.year,
      // }));
      // if (!_.find(this.yearList, { code: this.year })) {
      //   this.year = (_.last(this.yearList) || {}).code;
      // }
      this.init();
    });
  },
  methods: {
    init() {
      // 根据this.year找学年数据,默认进入是当前学年
      this.listen = _.orderBy((_.find(this.listenAll, { year: `${this.year}` }) || {}).data || [], 'code');
      // 列表默认显示该学年所有数据
      this.listenList = this.listen;
      // 获取出版社
      this.versionList = _.reduce(
        _.uniqBy(this.listen, 'version'),
        (result, value) => {
          result.push({
            name: value.version,
          });
          return result;
        },
        [],
      );
      // 获取年级
      this.gradeList = this.gradeListTemp;
      this.version = '';
      this.grade = '';
    },
    /* 筛选学年 */
    yearChange() {
      this.init();
    },
    /* 出版社筛选, 年级筛选, 分册筛选 */
    versionChange(type) {
      if (type === '1') {
        // 筛选的是出版社，获取对应的年级
        const data = _.filter(this.listen, val => val.version === this.version);
        this.gradeList = _.reduce(
          _.uniqBy(data, 'grade'),
          (result, value) => {
            const t = _.find(this.gradeListTemp, { code: value.grade });
            if (t) {
              result.push(t);
            }
            return result;
          },
          [],
        );
      }
      let temp = this.listen;
      if (this.version) {
        temp = _.filter(temp, val => val.version === this.version);
      }
      if (this.grade) {
        temp = _.filter(temp, val => val.grade === this.grade);
      }
      if (this.volume) {
        temp = _.filter(temp, val => val.volume === this.volume);
      }
      this.listenList = temp;
    },
    /* 播放 */
    bofang(val) {
      // const a = encodeURIComponent();
      // decodeURIComponent(a)
      this.$router.push({
        path: '/play',
        query: { url: encodeURIComponent(val.url), name: val.name },
      });
    },
    /* 下载 */
    download(val) {
      const request = new XMLHttpRequest();
      request.responseType = 'blob';
      request.open('GET', val.url);
      request.onload = () => {
        const url = window.URL.createObjectURL(request.response);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = val.name;
        a.click();
      };
      request.send();
    },
  },
};
</script>

<style lang="less" scoped>
.tingli {
  width: 100%;
  padding-top: 168px;
  height: calc(100% - 104px);
  background: #eff4f8;
  position: relative;
}
.t-selects {
  width: 1200px;
  height: 80px;
  margin: auto;
  background: #fff;
}
.select-ver {
  margin-top: 24px;
  margin-left: 32px;
  width: 274px;
}
.select-grade {
  margin-top: 24px;
  margin-left: 16px;
  width: 274px;
}
.t-list {
  position: relative;
  width: 1200px;
  height: calc(100% - 164px);
  padding-bottom: 68px;
  overflow-y: scroll;
  margin: auto;
  margin-top: 16px;
  background: #fff;
}
.l-title {
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #000000;
  line-height: 25px;
  padding-top: 26px;
  margin-left: 25px;
  margin-bottom: 19px;
}
.t-line {
  width: 1151px;
  height: 0px;
  border-top: 1px solid #eeeeee;
  margin-top: 0px;
  margin-left: 25px;
}
.t-content {
  height: 69px;
  padding-left: 25px;
  position: relative;
  &:hover {
    background: #fafafa;
    .l-bofang {
      display: block;
    }
    .l-down {
      display: block;
    }
  }
}
.l-name {
  padding-top: 13px;
  width: auto;
  height: 22px;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}
.l-name-bf {
  color: #1890ff;
}
.l-size {
  margin-top: 2px;
  width: auto;
  height: 20px;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
}
.l-bofang {
  display: none;
  width: 64px;
  height: 32px;
  background: #1890ff;
  border-radius: 2px;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  color: #ffffff;
  position: absolute;
  top: 19px;
  right: 44px;
  cursor: pointer;
}
.l-down {
  display: none;
  width: 62px;
  height: 30px;
  background: #ffffff;
  border-radius: 2px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  color: #1890ff;
  border: 1px solid #1890ff;
  position: absolute;
  top: 19px;
  right: 114px;
  cursor: pointer;
}
.bfing {
  width: 16px;
  height: 17px;
  position: absolute;
  top: 26px;
  right: 74px;
}
.t-bottom {
  width: 1200px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px -8px 8px 0px rgba(0, 0, 0, 0.04);
  position: absolute;
  bottom: 0px;
  left: calc(50% - 600px);
}
.bf-button-f {
  width: 28px;
  height: 28px;
  margin-top: 16px;
  margin-left: 20px;
  cursor: pointer;
  float: left;
}
.bf-button {
  width: 28px;
  height: 28px;
}
.jindu {
  height: 2px;
  width: 1000px;
  margin-top: 29px;
  float: left;
}
.already-time {
  height: 17px;
  font-size: 12px;
  color: #adadad;
  line-height: 17px;
  margin-left: 24px;
  margin-right: 17px;
  margin-top: 22px;
  float: left;
}
.total-time {
  font-size: 12px;
  color: #adadad;
  height: 17px;
  line-height: 17px;
  margin-top: 22px;
  margin-left: 16px;
  float: left;
}
/*滚动条样式*/
.t-list::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.t-list::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
</style>
